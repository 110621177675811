import {
  PdfFile,
  WordFile,
  ExcelFile,
  ZipFile,
  RarFile,
  File,
  JpgFile,
  PngFile,
} from "../../../assets/index";

export default function GenerateFileIcon({ fileType }) {
  let iconSrc = File;
  let iconAlt = "File Icon";
  let iconHeight = "50px";
  const lowerCaseFileType = fileType.toLowerCase();

  switch (true) {
    case lowerCaseFileType.includes("pdf"):
      iconSrc = PdfFile;
      iconAlt = "PDF Icon";
      break;
    case lowerCaseFileType.includes("word"):
      iconSrc = WordFile;
      iconAlt = "Word Icon";
      break;
    case lowerCaseFileType.includes("excel"):
      iconSrc = ExcelFile;
      iconAlt = "Excel Icon";
      break;
    case lowerCaseFileType.includes("zip"):
      iconSrc = ZipFile;
      iconAlt = "Zip Icon";
      break;
    case lowerCaseFileType.includes("rar"):
      iconSrc = RarFile;
      iconAlt = "Rar Icon";
      break;
    case lowerCaseFileType.includes("jpeg"):
      iconSrc = JpgFile;
      iconAlt = "Jpeg Icon";
      break;
    case lowerCaseFileType.includes("png"):
      iconSrc = PngFile;
      iconAlt = "Png Icon";
      break;
    default:
      break;
  }
  return <img src={iconSrc} alt={iconAlt} style={{ height: iconHeight }} />;
}
