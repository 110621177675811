export const APP_CONFIG = "APP_CONFIG";

export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

///Global
export const SOCKETCONNECTED = "SOCKETCONNECTED";
export const START_SHAKING = "START_SHAKING";

///Users
export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";

/// nOTIFICATIONS
export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const LIST_NOTIFICATIONS_LOADING = "LIST_NOTIFICATIONS_LOADING";
export const LIST_NOTIFICATIONS_ERROR = "LIST_NOTIFICATIONS_ERROR";
export const INSERT_NEW_NOTIFICATION = "INSERT_NEW_NOTIFICATION";
export const UPDATE_UN_READ = "UPDATE_UN_READ";

//Projects
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const PROJECTS_ERROR = "PROJECTS_ERROR";
export const GET_PROJECTS = "GET_PROJECTS";
export const CREATE_PROJECT = "CREATE_PROJECT";

//Single Project
export const PROJECT_LOADING = "PROJECT_LOADING";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const GET_PROJECT = "GET_PROJECT";

//Requests
export const REQUESTS_LOADING = "REQUESTS_LOADING";
export const REQUESTS_ERROR = "REQUESTS_ERROR";
export const GET_REQUESTS = "GET_REQUESTS";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";

export const REQUEST_ERROR = "REQUEST_ERROR";
export const REQUEST_LOADING = "REQUEST_LOADING";
export const GET_REQUEST = "GET_REQUEST";

//Offers
export const OFFERS_LOADING = "OFFERS_LOADING";
export const OFFERS_ERROR = "OFFERS_ERROR";
export const GET_OFFERS = "GET_OFFERS";
export const CREATE_OFFER = "CREATE_OFFER";

//Under Review Offers
export const REVIEW_OFFERS_LOADING = "REVIEW_OFFERS_LOADING";
export const REVIEW_OFFERS_ERROR = "REVIEW_OFFERS_ERROR";
export const GET_REVIEW_OFFERS = "GET_REVIEW_OFFERS";

//In Progress Offers
export const PROGRESS_OFFERS_LOADING = "PROGRESS_OFFERS_LOADING";
export const PROGRESS_OFFERS_ERROR = "PROGRESS_OFFERS_ERROR";
export const GET_PROGRESS_OFFERS = "GET_PROGRESS_OFFERS";

//Bills
export const BILLS_LOADING = "BILLS_LOADING";
export const BILLS_ERROR = "BILLS_ERROR";
export const GET_BILLS = "GET_BILLS";
export const CREATE_BILL = "CREATE_BILL";
export const UPDATE_BILL = "UPDATE_BILL";

//Categories
export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";

export const UPDATE_STATUS = "UPDATE_STATUS";

export const LOG_LOADING = "LOG_LOADING";
export const LOG_ERROR = "LOG_ERROR";
export const GET_LOG = "GET_LOG";
export const LOG_SUPPLIER_LOADING = "LOG_SUPPLIER_LOADING";
export const LOG_SUPPLIER_ERROR = "LOG_SUPPLIER_ERROR";
export const GET_LOG_SUPPLIER = "GET_LOG_SUPPLIER";
// Hospitals
export const GET_HOSPITALS = "GET_HOSPITALS";
export const HOSPITALS_ERROR = "HOSPITALS_ERROR";
export const HOSPITALS_LOADING = "HOSPITALS_LOADING";
export const GET_HOSPITAL = "GET_HOSPITAL";
export const HOSPITAL_ERROR = "HOSPITAL_ERROR";
export const HOSPITAL_LOADING = "HOSPITAL_LOADING";

// Doctors
export const GET_DOCTORS = "GET_DOCTORS";
export const DOCTORS_ERROR = "DOCTORS_ERROR";
export const DOCTORS_LOADING = "DOCTORS_LOADING";
export const GET_DOCTOR = "GET_DOCTOR";
export const DOCTOR_ERROR = "DOCTOR_ERROR";
export const DOCTOR_LOADING = "DOCTOR_LOADING";

// Entertainment
export const GET_ENTERTAINMENTS = "GET_ENTERTAINMENTS";
export const ENTERTAINMENTS_ERROR = "ENTERTAINMENTS_ERROR";
export const ENTERTAINMENTS_LOADING = "ENTERTAINMENTS_LOADING";

// Transportation
export const GET_TRANSPORTATION = "GET_TRANSPORTATION";
export const TRANSPORTATION_ERROR = "TRANSPORTATION_ERROR";
export const TRANSPORTATION_LOADING = "TRANSPORTATION_LOADING";

//Roles
export const ROLES_LOADING = "ROLES_LOADING";
export const ROLES_ERROR = "ROLES_ERROR";
export const GET_ROLES = "GET_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";

//Chats
export const CHATS_LOADED = "CHATS_LOADED";
export const ADD_SINGLE_CHAT = "ADD_SINGLE_CHAT";
export const CHATS_LOADING = "CHATS_LOADING";
export const CHATS_ERROR = "CHATS_ERROR";
export const UPDATE_CHATS_WHEN_MESSAGE_GOT = "UPDATE_CHATS_WHEN_MESSAGE_GOT";
export const UPDATE_CHAT_UNSEEN_MESSAGE_COUNT =
  "UPDATE_CHAT_UNSEEN_MESSAGE_COUNT";

//SingleChat
export const SINGLE_CHAT_LOADED = "SINGLE_CHAT_LOADED";
export const SINGLE_CHAT_LOADING = "SINGLE_CHAT_LOADING";
export const SINGLE_CHAT_ERROR = "SINGLE_CHAT_ERROR";
export const SET_TEMP_CHAT = "SET_TEMP_CHAT";
export const INSERT_NEW_MESSAGE = "INSERT_NEW_MESSAGE";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const EMPTY_SINGLE_CHAT = "EMPTY_SINGLE_CHAT";
