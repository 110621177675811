import { getRequest, postRequest } from "../connection/network";

const entityApi = "entity";
const permissionApi = "permission";

export const listPermissionsService = async ({ page, limit, roleId }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await getRequest({
      token,
      query: { page, limit, roleId },
      endPoint: entityApi,
    });

    return {
      data,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const createPermissionsService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await postRequest({
      body: sendData,
      endPoint: permissionApi,
      token,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};
