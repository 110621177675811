import {
  Checkbox,
  FormControlLabel,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const PermissionsRow = ({
  entity,
  disabled,
  changePermissions,
  permissions,
  isSuperAdmin,
}) => {
  const { canRead, canCreate, canUpdate, canDelete } = permissions;

  return (
    <>
      <TableRow role="checkbox">
        <TableCell>
          <Typography fontWeight="bold">{entity?.name}</Typography>
        </TableCell>
        <TableCell>
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                sx={{ cursor: disabled && "default" }}
                checked={canRead || isSuperAdmin}
                onChange={changePermissions}
                name="canRead"
              />
            }
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                sx={{ cursor: disabled && "default" }}
                checked={canCreate || isSuperAdmin}
                onChange={changePermissions}
                name="canCreate"
              />
            }
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                sx={{ cursor: disabled && "default" }}
                checked={canUpdate || isSuperAdmin}
                onChange={changePermissions}
                name="canUpdate"
              />
            }
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                sx={{ cursor: disabled && "default" }}
                checked={canDelete || isSuperAdmin}
                onChange={changePermissions}
                name="canDelete"
              />
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default PermissionsRow;
