import {
  loading,
  error,
  setList,
  assignData,
  statusData,
  setChartsData,
} from "../Slices/MedicalTreatment";
import {
  assignAdminService,
  assignFreelanceSupplierService,
  changeStatusService,
  getReportService,
  getService,
} from "../../Services/MedicalTreatment";

export const getAction = (id) => async (dispatch) => {
  dispatch(loading());
  try {
    const response = await getService(id);
    if (response?.error) {
      dispatch(error(response.error));
      return;
    } else {
      dispatch(setList(response));
      return;
    }
  } catch (error) {
    return;
  }
};

export const getReportAction = () => async (dispatch) => {
  dispatch(loading());
  try {
    const response = await getReportService();
    if (response?.error) {
      dispatch(error(response.error));
      return;
    } else {
      dispatch(setChartsData(response));
      return;
    }
  } catch (error) {
    return;
  }
};

export const assignFreelanceSupplierAction =
  ({ sendData }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await assignFreelanceSupplierService({ sendData });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(
          assignData({
            data: response?.data?.freelanceSuppliers,
            id: sendData?.medicalTreatmentId,
          })
        );
        return;
      }
    } catch (error) {
      return;
    }
  };

export const changeStatusAction =
  ({ sendData }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await changeStatusService({ sendData });
      if (response?.error) {
        dispatch(error(response.error));
        return;
      } else {
        dispatch(statusData(response?.data));
        return;
      }
    } catch (error) {
      return;
    }
  };

export const assignAdminAction =
  ({ sendData, cb }) =>
  async (dispatch) => {
    dispatch(loading());
    try {
      const response = await assignAdminService({ sendData });
      if (response?.error) {
        return response.error;
      } else {
        cb && cb();
        return;
      }
    } catch (error) {
      return;
    }
  };
