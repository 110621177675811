import {
  LOG_LOADING,
  LOG_ERROR,
  GET_LOG,
  LOG_SUPPLIER_LOADING,
  LOG_SUPPLIER_ERROR,
  GET_LOG_SUPPLIER,
} from "../Actions/types";
import { createReducer } from "@reduxjs/toolkit";

const supplierInitial = {
  loading: false,
  data: [],
  error: "",
  thisPage: 0,
  allPages: 0,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  allPages: 0,
  supplier: supplierInitial,
};

const logsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LOG_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOG_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_LOG, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload?.thisPage;
      state.allPages = payload?.allPages;
      state.loading = false;
    })

    .addCase(LOG_SUPPLIER_LOADING, (state) => {
      state.supplier.loading = true;
    })
    .addCase(LOG_SUPPLIER_ERROR, (state, { payload }) => {
      state.supplier.error = payload;
      state.supplier.loading = false;
    })
    .addCase(GET_LOG_SUPPLIER, (state, { payload }) => {
      state.supplier.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.supplier?.data, ...payload?.data];
      state.supplier.thisPage = payload.thisPage;
      state.supplier.allPages = payload.allPages;
      state.supplier.loading = false;
    });
});

export default logsReducer;
