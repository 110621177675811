import { Typography } from "@mui/material";

export default function TreatmentFormLabel({ title, description }) {
  return (
    <Typography variant="body1" sx={{ color: "#191825" }}>
      <span style={{ color: "#000", fontWeight: "600" }}>
        {title} {"-"}
      </span>{" "}
      {description}
    </Typography>
  );
}
