import {
  GET_DOCTORS,
  DOCTORS_ERROR,
  DOCTORS_LOADING,
  GET_DOCTOR,
  DOCTOR_ERROR,
  DOCTOR_LOADING,
} from "../Actions/types";
import { createReducer } from "@reduxjs/toolkit";

const singleDoctorInitial = {
  loading: false,
  data: {},
  error: null,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  singleDoctor: singleDoctorInitial,
};

const doctorsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(DOCTORS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(DOCTORS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_DOCTORS, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(DOCTOR_LOADING, (state) => {
      state.singleDoctor.loading = true;
    })
    .addCase(DOCTOR_ERROR, (state, { payload }) => {
      state.singleDoctor.error = payload;
      state.singleDoctor.loading = false;
    })
    .addCase(GET_DOCTOR, (state, { payload }) => {
      state.singleDoctor.data = payload?.data;
      state.singleDoctor.loading = false;
    });
});

export default doctorsReducer;
