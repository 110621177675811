import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  thisPage: "",
  allPages: "",
};

const operationSlice = createSlice({
  name: "operation",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setData(state, action) {
      state.data =
        action.payload?.thisPage === 1
          ? action.payload?.data
          : [...state?.data, ...action.payload?.data];
      state.thisPage = action.payload.thisPage;
      state.allPages = action.payload.allPages;
      state.loading = false;
    },
    addData(state, action) {
      state.data.unshift(action.payload);
      state.loading = false;
      state.error = false;
    },
    editData(state, action) {
      const foundProduct = state.data.filter(
        (e) => e.id === action.payload?.data?.id
      )[0];
      const index = state.data.indexOf(foundProduct);
      state.data[index] = { ...state.data[index], ...action.payload?.data };
      state.loading = false;
    },
    deleteData(state, action) {
      state.data = state.data.filter((e) => e.id !== action.payload?.id);
      state.loading = false;
    },
  },
});

export const { loading, error, setData, addData, editData, deleteData } =
  operationSlice.actions;

export default operationSlice.reducer;
