import { Alert, Fade, Snackbar } from "@mui/material";
import { useState } from "react";

export default function CustomSnackbar({ open, Transition, isError, message }) {
  const [snackBar, setSnackBar] = useState({
    open: open,
    Transition: Transition,
    isError: isError,
  });

  const handleSnackBarClose = () => {
    setSnackBar({
      open: false,
      Transition: Fade,
      isError: false,
    });
  };

  return (
    <Snackbar
      open={snackBar.open}
      autoHideDuration={3000}
      onClose={handleSnackBarClose}
      TransitionComponent={snackBar.Transition}
      key={snackBar.Transition.name}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Alert
        onClose={handleSnackBarClose}
        severity={snackBar.isError ? "error" : "success"}
        sx={{
          width: "100%",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
