import {
  OFFERS_LOADING,
  OFFERS_ERROR,
  GET_OFFERS,
  CREATE_OFFER,
  REVIEW_OFFERS_LOADING,
  REVIEW_OFFERS_ERROR,
  GET_REVIEW_OFFERS,
  PROGRESS_OFFERS_LOADING,
  PROGRESS_OFFERS_ERROR,
  GET_PROGRESS_OFFERS,
} from "../Actions/types";
import { createReducer } from "@reduxjs/toolkit";

const reviewOffersInitial = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const inProgressOffersInitial = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  reviewOffers: reviewOffersInitial,
  inProgressOffers: inProgressOffersInitial,
};

const offersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(OFFERS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(OFFERS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_OFFERS, (state, { payload }) => {
      state.data = payload?.data;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_OFFER, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(REVIEW_OFFERS_LOADING, (state) => {
      state.reviewOffers.loading = true;
    })
    .addCase(REVIEW_OFFERS_ERROR, (state, { payload }) => {
      state.reviewOffers.error = payload;
      state.reviewOffers.loading = false;
    })
    .addCase(GET_REVIEW_OFFERS, (state, { payload }) => {
      state.reviewOffers.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.reviewOffers?.data, ...payload?.data];
      state.reviewOffers.thisPage = payload.thisPage;
      state.reviewOffers.nextPage = payload.nextPage;
      state.reviewOffers.hasMore = payload.hasMore;
      state.reviewOffers.count = payload.count;
      state.reviewOffers.loading = false;
    })
    .addCase(PROGRESS_OFFERS_LOADING, (state) => {
      state.inProgressOffers.loading = true;
    })
    .addCase(PROGRESS_OFFERS_ERROR, (state, { payload }) => {
      state.inProgressOffers.error = payload;
      state.inProgressOffers.loading = false;
    })
    .addCase(GET_PROGRESS_OFFERS, (state, { payload }) => {
      state.inProgressOffers.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.inProgressOffers?.data, ...payload?.data];
      state.inProgressOffers.thisPage = payload.thisPage;
      state.inProgressOffers.nextPage = payload.nextPage;
      state.inProgressOffers.hasMore = payload.hasMore;
      state.inProgressOffers.count = payload.count;
      state.inProgressOffers.loading = false;
    });
});

export default offersReducer;
