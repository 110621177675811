import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "../Components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { appConfigAction } from "../Store/Actions/app/index";
import TreatmentForm from "../Pages/TreatmentForm";
import Permissions from "../Pages/Permissions";

const Login = lazy(() => import("../Pages/login"));
const SingleStep = lazy(() => import("../Pages/SingleStep"));
const Notification = lazy(() => import("../Pages/Notification"));
const NavBar = lazy(() => import("../Layout/Navbar"));
const Dashboard = lazy(() => import("../Pages/dashboard/index"));
const Chats = lazy(() => import("../Pages/Chats/Chats"));

export const Router = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  const appLoading = useSelector((state) => state.app.loading);

  useEffect(() => {
    dispatch(appConfigAction());
  }, [dispatch]);

  if (appLoading) return <PageLoader />;

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<PageLoader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/plan/:id"
          element={
            <Suspense fallback={<PageLoader />}>
              <NavBar />
              <SingleStep />
            </Suspense>
          }
        />

        <Route
          path="/medical-treatment/:id"
          element={
            <Suspense fallback={<PageLoader />}>
              <NavBar />
              <TreatmentForm />
            </Suspense>
          }
        />

        <Route
          path="/notification"
          element={
            <Suspense fallback={<PageLoader />}>
              <NavBar />
              <Notification />
            </Suspense>
          }
        />

        <Route
          path="/permissions/:id"
          element={
            <Suspense fallback={<PageLoader />}>
              <NavBar />
              <Permissions />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense fallback={<PageLoader />}>
              <NavBar />
              <Dashboard />
            </Suspense>
          }
        />

        <Route
          path="/chat"
          element={
            <Suspense fallback={<PageLoader />}>
              <NavBar />
              <Chats />
            </Suspense>
          }
        />

        <Route path="404" element={<>Not Found</>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
};

export default Router;
