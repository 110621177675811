import { postRequest, getRequest } from "../connection/network";
import {
  addChatToLocalStorageChats,
  storeFirstTimeChatLocalStorage,
  loadMoreMessagesToLocalStorage,
} from "../storage/chats";
import { fetchSingleUser, storeUsersToLO } from "../storage/users";
import store from "../Store";
import { SET_CURRENT_USERS } from "../Store/Actions/types";

const endPoints = {
  getChats: "chat",
  media: "chat/messages/media",
  messages: "chat/messages",
  members: "chat/members",
  clear: "chat/clear",
  delete: "chat",
};

const getChats = async ({ page, limit, type }) => {
  try {
    const token = localStorage.getItem("token");
    const { data: resData } = await getRequest({
      token,
      query: { page, limit, type },
      endPoint: endPoints.getChats,
    });

    let {
      meta,
      data: { chats, usersById },
    } = resData;

    let data = chats;

    let pagination = {
      thisPage: {
        groups: parseInt(meta.thisPage),
        private: parseInt(meta.thisPage),
        thread: parseInt(meta.thisPage),
        bots: parseInt(meta.thisPage),
      },
      hasMore: {
        groups: true,
        private: true,
        thread: true,
        bots: true,
      },
    };

    if (type !== "all") {
      pagination["thisPage"] = {
        [type]: parseInt(meta.thisPage),
      };

      if (chats?.length < limit) {
        pagination["hasMore"] = {
          [type]: false,
        };
      }
    }

    storeUsersToLO(usersById);
    return {
      data,
      firstTime: type === "all" ? true : false,
      pagination,
    };
  } catch (error) {
    throw error;
  }
};

const sendMedia = async (data, cb) => {
  try {
    const token = localStorage.getItem("token");
    await postRequest({
      body: data,
      token,
      endPoint: endPoints.media,
      isFormData: true,
    });
    cb && cb(null);
  } catch (error) {
    cb && cb(error);
    console.error(error);
  }
};

export const fetchSingleChat = async (
  { page, limit, chatId, loadMore, getUsersChat },
  insertNew
) => {
  const token = localStorage.getItem("token");
  const { data: resData } = await getRequest({
    token,
    query: { page, limit, chatId, getUsersChat },
    endPoint: endPoints.messages,
  });

  const {
    meta,
    data: { messages, chat, usersById, otherChats },
  } = resData;
  let hasMore = true;

  if (messages?.length < limit) {
    hasMore = false;
  }

  storeUsersToLO(usersById);
  meta.thisPage = parseInt(meta.thisPage);
  meta.allPages = parseInt(meta.allPages);
  meta.count = parseInt(meta.count);
  if (insertNew) {
    await addChatToLocalStorageChats(chat.id, {
      ...chat,
      otherChats,
      messages: { data: messages, meta, hasMore },
      hasMore,
    });
  }

  if (!loadMore) {
    await storeFirstTimeChatLocalStorage(chatId, {
      ...chat,
      messages: { data: messages, hasMore, meta },
      otherChats,
      hasMore,
    });
  } else {
    await loadMoreMessagesToLocalStorage(chatId, {
      data: messages,
      meta,
      hasMore,
    });
  }
  let otherUser = chat?.otherUser;

  if (otherUser) {
    chat.otherUser = fetchSingleUser(otherUser?.id);
  }
  store.dispatch({
    type: SET_CURRENT_USERS,
    payload: usersById || {},
  });

  return {
    data: messages,
    chat,
    otherChats,
    hasMore,
    ...(meta || {}),
  };
};

export const getChatMediaService = async ({
  chatId,
  type = "media",
  page,
  limit,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
        type,
        chatId,
      },
      endPoint: endPoints.media,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export { getChats, sendMedia };
