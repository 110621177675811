import {
  CHATS_LOADED,
  CHATS_LOADING,
  CHATS_ERROR,
  UPDATE_CHATS_WHEN_MESSAGE_GOT,
  UPDATE_CHAT_UNSEEN_MESSAGE_COUNT,
  ADD_SINGLE_CHAT,
} from "../Actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  error: "",
  loading: true,
  data: [],
  byId: {},
  allIds: [],
  currentType: "private",
  currentTab: "public",
  pagination: {
    thisPage: {
      groups: 0,
      private: 0,
      threads: 0,
      bots: 0,
    },
    hasMore: {
      groups: true,
      private: true,
      threads: true,
      bots: true,
    },
  },
};

const chatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ADD_SINGLE_CHAT, (state, { payload }) => {
      let isFound = state?.data?.findIndex((c) => c?.id === payload?.id);
      if (isFound > -1) {
        state.data[isFound] = { ...payload };
      } else {
        if (payload?.type !== "page")
          state.data.push({ ...payload, unseenCount: 0 });
      }
    })
    .addCase(UPDATE_CHAT_UNSEEN_MESSAGE_COUNT, (state, { payload }) => {
      const chatIndex = state.data.findIndex((c) => c.id === payload?.chatId);
      let chat = state.data[chatIndex];
      if (chat) {
        chat.unseenCount =
          typeof payload.count === "number"
            ? payload.count
            : chat.unseenCount++;
        if (chat?.mentioned && payload.count < 1) chat.mentioned = undefined;
      }
    })
    .addCase(UPDATE_CHATS_WHEN_MESSAGE_GOT, (state, { payload }) => {
      let chatIndex = state.data.findIndex(
        (c) => c.id === parseInt(payload?.message?.chatId)
      );

      if (chatIndex > -1) {
        const chat = state.data[chatIndex];
        if (chat) {
          chat.lastMessage = payload.message;
          chat.unseenCount = payload.inc
            ? chat.unseenCount + 1
            : chat.unseenCount;
        }
        state.data.splice(chatIndex, 1);
        chat?.type === "privateBot"
          ? state.data.unshift(chat)
          : state.data.splice(1, 0, chat);
      }
    })
    .addCase(CHATS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(CHATS_LOADING, (state) => {
      state.error = false;
      state.loading = true;
    })
    .addCase(CHATS_LOADED, (state, { payload }) => {
      state.data = payload?.reset
        ? payload?.data
        : [...state?.data, ...payload?.data];

      state.pagination.thisPage = {
        ...(state.pagination.thisPage || {}),
        ...(payload?.pagination?.thisPage || {}),
      };

      state.pagination.hasMore = {
        ...(state.pagination.hasMore || {}),
        ...(payload?.pagination?.hasMore || {}),
      };

      state.loading = false;
    });
});

export default chatReducer;
